<template>
  <BaseModal
    :title="_T('@Write review')"
    :modal-width="1015"
    @on-close-modal="onCloseModal"
  >
    <form class="review-modal">
      <div class="review-modal__rating">
        <NuxtRating
          :rating-size="44"
          :rating-step="1"
          :read-only="false"
          :rating-value="form.rating"
          active-color="#fbbd09"
          @rating-selected="onSelectRating"
        />
        <p
          v-if="v.rating.$invalid && v.rating.$dirty"
          class="review-modal__error-label"
        >
          {{ _T("@Entry field required") }}
        </p>
      </div>
      <div class="review-modal__inputs">
        <InputText
          v-model="form.name"
          :validate="v.name"
          :maxlength="255"
          :caption="_T('@Name')"
          autocomplete-type="given-name"
          wide
        />
        <InputEmail
          v-model="form.email"
          :validate="v.email"
          caption="E-mail"
          wide
        />
        <InputText
          v-model="form.pros"
          :validate="v.pros"
          :maxlength="255"
          :minlength="3"
          :caption="_T('@Pros')"
          :required="false"
          wide
        />
        <InputText
          v-model="form.cons"
          :validate="v.cons"
          :maxlength="255"
          :minlength="3"
          :caption="_T('@Cons')"
          :required="false"
          wide
        />
      </div>
      <InputTextarea
        v-model="form.content"
        :validate="v.content"
        :maxlength="2048"
        :minlength="3"
        :caption="_T('@Your comment')"
      />
      <BaseCheckbox v-model="form.isReplyTracked" :validate="v.isReplyTracked">
        <span>
          {{ _T("@Product notify me of replies by email") }}
        </span>
      </BaseCheckbox>
      <div class="review-modal__checkbox-wrp">
        <ButtonPrimary
          type="button"
          :aria-label="_T('@Post a review')"
          :disabled="addReviewHandler.isHandled"
          @click.prevent="onAddReview"
        >
          {{ _T("@Post a review") }}
        </ButtonPrimary>
        <BaseCheckbox
          v-model="form.isAgreementAccepted"
          :validate="v.isAgreementAccepted"
        >
          <span>
            {{ _T("@I agree with") }}
            <MyLink name="reviews-policy" target="_blank">
              {{ _T("@Reviews policy") }}
            </MyLink>
          </span>
        </BaseCheckbox>
      </div>
    </form>
  </BaseModal>
</template>

<script setup>
import InputTextarea from "~~/modules/shared/inputs/InputTextarea.vue";
import useVuelidate from "@vuelidate/core";
import InputText from "~~/modules/shared/inputs/InputText.vue";
import InputEmail from "~/modules/shared/inputs/InputEmail.vue";
import {
  reviewModal,
  successfulModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";
import {
  emailValidate,
  maxLengthValidate,
  minLengthValidate,
  requiredValidate,
  sameAsValidate,
} from "~/utils/validators";
import { modalFormStore } from "~/modules/shared/modals/store/modalFormStore";
import { useUserStore } from "~/store/userStore";
import { useSingletonHandler } from "~/uses/useSingletonHandler";
import { useStrapiApi } from "~/uses/useMyFetch";
import { minValue } from "@vuelidate/validators";
import ButtonPrimary from "~/modules/shared/buttons/ButtonPrimary.vue";
import BaseModal from "~/modules/shared/modals/BaseModal.vue";
import BaseCheckbox from "~/modules/shared/inputs/BaseCheckbox.vue";

const modalStore = useModalStore();
const formStore = modalFormStore();
const userStore = useUserStore();

const { currentUser: user, userFullName } = storeToRefs(userStore);
const product = storeToRefs(modalStore).getExtra.value(reviewModal);

const onSelectRating = (rating) => {
  form.rating = rating;
};

onMounted(() => {
  form.productCode = product.code;
  form.userId = user.value?.id;

  if (null !== user.value) {
    form.name = userFullName.value;
    form.email = user.value.email;
  }

  formStore.addForm({ v, requestOptions, id: "review" });
});

const successfulData = {
  title: _T("@Successful modal title"),
  description: _T("@Review sent successfully"),
  isWithTimeout: true,
};

const form = reactive({
  userId: undefined,
  productCode: "",
  email: "",
  name: "",
  pros: null,
  cons: null,
  content: "",
  isReplyTracked: false,
  rating: 0,
  isAgreementAccepted: true,
});

const rules = computed(() => ({
  name: {
    required: requiredValidate(),
    maxLength: maxLengthValidate("@Maximum field length", 255),
  },
  email: {
    required: requiredValidate(),
    email: emailValidate(),
    maxLength: maxLengthValidate("@Maximum field length", 254),
  },
  pros: {
    minLength: minLengthValidate("@Minimum field length", 3),
    maxLength: maxLengthValidate("@Maximum field length", 512),
  },
  cons: {
    minLength: minLengthValidate("@Minimum field length", 3),
    maxLength: maxLengthValidate("@Maximum field length", 512),
  },
  content: {
    required: requiredValidate(),
    minLength: minLengthValidate("@Minimum field length", 3),
    maxLength: maxLengthValidate("@Maximum field length", 2048),
  },
  isReplyTracked: {},
  rating: {
    required: requiredValidate,
    minValue: minValue(1),
  },
  isAgreementAccepted: {
    sameAs: sameAsValidate("@You must accept the terms and conditions", true),
  },
}));

const v = useVuelidate(rules, form);

const requestOptions = () => {
  return {
    userId: form.userId,
    productCode: form.productCode,
    name: form.name,
    email: form.email,
    pros: form.pros || null,
    cons: form.cons || null,
    content: form.content,
    isReplyTracked: form.isReplyTracked,
    rating: form.rating,
  };
};

const addReviewHandler = useSingletonHandler(async () => {
  const formData = new FormData();

  formData.append("data", JSON.stringify(requestOptions()));

  return useStrapiApi("/product/reviews", {
    method: "POST",
    body: formData,
  }).then(() => {
    modalStore.toggleModal(reviewModal);

    modalStore.toggleModal(successfulModal, successfulData);
  });
});

const onAddReview = async () => {
  if (!formStore.isFormsReady()) {
    return;
  }

  return await addReviewHandler.handle();
};

const onCloseModal = () => {
  modalStore.toggleModal(reviewModal);
};

onUnmounted(() => {
  formStore.resetForms();
});
</script>

<style lang="scss" scoped>
.review-modal {
  @include flex-container(column, space-between);
  gap: 24px;

  padding: 24px 16px;

  &__rating {
    margin-bottom: 16px;
  }

  &__error-label {
    @include font(12, 16);
    color: var(--color-red-error-500);

    padding-inline: 8px;
  }

  &__inputs {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));

    gap: 24px;

    @include mobile {
      @include flex-container(column);
      gap: 16px;
    }
  }

  &__checkbox-wrp {
    width: min(100%, 330px);
    align-self: center;

    @include flex-container(column, center, center);
    gap: 24px;

    @include mobile {
      width: 100%;
    }
  }
}
</style>
